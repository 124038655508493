import request from '@/utils/request'

// 列表
export function quota_list (params) {
  return request({
    url: '/v1/quota/quota',
    method: 'get',
    params
  })
}

// 编辑
export function edit_quota (data) {
  return request({
    url: '/v1/quota/quota',
    method: 'put',
    data
  })
}

export function edit_quota_user (data) {
  return request({
    url: '/v1/account/current_user',
    method: 'put',
    data
  })
}

// 获取配额申请邮箱
export function get_email (params) {
  return request({
    url: '/v1/quota/super_admin_email',
    method: 'get',
    params
  })
}

// 查看个人信息
export function user (params) {
  return request({
    url: '/v1/account/current_user',
    method: 'get',
    params
  })
}

// 登录人有权限使用的应用
export function get_apps (params) {
  return request({
    url: '/v1/client/user_clients',
    method: 'get',
    params
  })
}
