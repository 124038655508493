<template>
  <div class="view">
    <Loading :show="false">
      <div class="header-title">{{ $route.meta.title }}</div>
      <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
        <div class="next-loading-wrap">
          <div class="sc-iBaQBe cGFAXW">
            <el-row>
              <el-col :span="24" :sm="12">
                <div class="next-card-title fLwpMi">
                  用户基本信息
                  <div style="margin-left: 16px">
                    <button
                      type="button"
                      class="
                        next-btn next-medium next-btn-primary next-btn-text
                      "
                      style="margin-right: 8px"
                      :disabled="!(userInfo.ldap_id == '' || !userInfo.ldap_id)"
                      @click="open_drawer"
                    >
                      <span class="next-btn-helper">编辑基本信息</span>
                    </button>
                  </div>
                </div>
              </el-col>
              <el-col v-if="$config.IF_SHOW_MAIN" :span="24" :sm="12" :lg="12" :xs="24">
                <div class="next-card-title fLwpMi" style="white-space: inherit;">
                  登录地址
                  <!-- <div style="margin-left: 16px"> -->
                  <a
                    :href="location"
                    target="_blank"
                    style="font-size: 12px; color: #0064c8; margin-right: 8px;margin-left: 16px;"
                    rel="noreferrer"
                  >{{ location }}</a
                  >
                  <span>
                    <el-tooltip v-model="isTooltip" manual content="已复制。" placement="bottom" effect="light">
                      <button
                        type="button"
                        class="next-btn next-medium next-btn-primary next-btn-text"
                        @click="copy(location)"
                      >
                        <span aria-haspopup="true" aria-expanded="false" />
                        <i
                          class="el-icon-copy-document"
                          style="margin: 0px 4px"
                        />
                        <span
                          data-spm-anchor-id="5176.2020520153.0.i0.6843336apVx5pw"
                        >复制登录地址</span
                        >
                      </button>
                    </el-tooltip>

                  </span>
                  <!-- </div> -->
                </div>
              </el-col>
            </el-row>
            <div class="next-row next-row-wrap wind-rc-data-fields">
              <form-label label="登录名称">
                <span>{{ userInfo.web_name || "-" }}</span>
              </form-label>
              <form-label label="UID"> {{ userInfo.id || "-" }} </form-label>
              <form-label label="显示名称">
                {{ userInfo.display_name || "-" }}
              </form-label>
              <form-label label="创建时间">
                {{ userInfo.create_time || "-" }}
              </form-label>
              <form-label label="手机">
                {{ userInfo.phone || "-" }}
              </form-label>
              <form-label label="邮箱">
                {{ userInfo.email || "-" }}
              </form-label>
              <form-label v-if="userInfo.ldap_id" label="ldap_id">
                {{ userInfo.ldap_id }}
              </form-label>
              <form-label v-if="userInfo.user_type==1" label="别名">
                {{ userInfo.alias }}
              </form-label>
            </div>
          </div>
        </div>
      </div>

      <div class="card-table">
        <div v-if="!show_list">
          <div class="next-card-title fLwpMi">
            域配额使用情况
            <div
              v-if="!$store.state.userInfo.is_super"
              style="margin-left: 16px"
            >
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="applyChangeQuota"
              >
                申请变更配额
              </button>
            </div>
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :header-cell-style="headerCallStyle"
          >
            <el-table-column
              prop="domain_name"
              label="域"
              show-overflow-tooltip
            />
            <el-table-column label="用户用量/配额">
              <template slot-scope="{ row }">
                <span>{{ row.user_count + "/" + row.user_sub_limit }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="group_limit" label="用户组用量/配额">
              <template slot-scope="{ row }">
                <span>{{ row.group_count + "/" + row.group_limit }}</span>
              </template>
            </el-table-column>
            <el-table-column label="角色用量/配额">
              <template slot-scope="{ row }">
                <span>{{ row.role_count + "/" + row.role_limit }}</span>
              </template>
            </el-table-column>
            <el-table-column label="权限用量/配额">
              <template slot-scope="{ row }">
                <span>{{
                  row.permission_count + "/" + row.permission_limit
                }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="$store.state.userInfo.is_super" label="操作">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="edit(row)"
                >
                  <span class="next-btn-helper">编辑</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="next-card-title fLwpMi mt20">可用应用</div>
        <div>
          <div
            v-for="(item, index) in appList"
            :key="index"
            :title="item.client_home_page"
            class="
              next-tag next-tag-default next-tag-medium next-tag-level-normal
              wind-tag-colored wind-tag-colored-light-steel-blue
            "
            tabindex="0"
            role="button"
            :style="{'margin-left':index>0?'5px':0}"
            aria-disabled="false"
            @click="link(item.client_home_page)"
          >
            <span class="next-tag-body">{{ item.client_name }}</span>
          </div>
          <el-result
            v-if="!loading && !appList.length"
            icon="info"
            title="当前暂无可用应用"
          />
        </div>
        <div class="next-card-title fLwpMi mt20">可申请应用</div>
        <el-table
          :data="not_can_use_client_list"
          border
          style="width: 100%">
          <el-table-column
            prop="client_name"
            label="应用名称">
            <template slot-scope="{row}">
              <el-link
                type="primary"
                :underline="false"
                @click="link(row.client_home_page)"
              >{{ row.client_name }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="main_client_admin"
            label="应用管理员（主用户）">
            <template slot-scope="{row}">
              <div v-for="(item,i) in row.main_client_admin||[]" :key="i" style="display: inline-block;">
                <el-popover
                  placement="top-start"
                  title="用户信息"
                  width="200"
                  trigger="hover">
                  <p>手机号：{{ item.phone ||'-' }}</p>
                  <p>邮箱：{{ item.email||'-' }}</p>
                  <el-tag
                    slot="reference"
                    style="margin:5px;"
                    size="small"
                    type="info"
                  >
                    {{ item.name }}
                  </el-tag>
                </el-popover>
              </div>

            </template>
          </el-table-column>
          <el-table-column
            prop="sub_client_admin"
            label="应用管理员（子用户）">
            <template slot-scope="{row}">
              <div v-for="(item,i) in row.sub_client_admin||[]" :key="i" style="display: inline-block;">
                <el-popover
                  placement="top-start"
                  title="用户信息"
                  width="200"
                  trigger="hover">
                  <p>手机号：{{ item.phone ||'-' }}</p>
                  <p>邮箱：{{ item.email||'-' }}</p>
                  <el-tag
                    slot="reference"
                    style="margin:5px;"
                    size="small"
                    type="info"
                  >
                    {{ item.name }}
                  </el-tag>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </Loading>
    <!-- 更新基本信息 -->
    <el-drawer
      ref="drawer_user"
      custom-class="drawer-warp"
      title="更新用户"
      size="20%"
      :visible.sync="drawer_user"
      @closeDrawer="closeDrawer('ruleForm', 'drawer_user')"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名称">
            <el-input v-model="ruleForm.name" :disabled="storeInfo.user_type==1&&storeInfo.domain_id==1" />
          </el-form-item>
          <el-form-item label="显示名称">
            <el-input v-model="ruleForm.display_name" />
          </el-form-item>
          <el-form-item label="手机">
            <el-input v-model="ruleForm.phone" />
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="ruleForm.email" />
          </el-form-item>
          <el-form-item v-if="userInfo.user_type==1" label="别名">
            <el-input v-model="ruleForm.alias" />
          </el-form-item>
          <el-form-item prop="is_update_password">
            <template slot="label">
              <el-checkbox v-model="ruleForm.is_update_password"
              >更改密码</el-checkbox
              >
            </template>
          </el-form-item>
          <template v-if="ruleForm.is_update_password">
            <el-form-item prop="old_password" label="旧密码">
              <template slot="label">
                旧密码
                <el-tooltip
                  :content="oldTooltip"
                  placement="bottom"
                  effect="light"
                >
                  <i style="font-size: 14px" class="el-icon-question" />
                </el-tooltip>
              </template>

              <el-input
                v-model="ruleForm.old_password"
                placeholder="请输入旧密码"
                show-password
              />
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input
                v-model="ruleForm.new_password"
                type="password"
                placeholder="长度大于10,包含:大写,小写,数字,特殊字符"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <el-form-item label="确认密码" prop="confirm_password">
              <el-input
                v-model="ruleForm.confirm_password"
                placeholder="长度大于10,包含:大写,小写,数字,特殊字符"
                type="password"
                autocomplete="off"
                show-password
              />
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submitUser('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="closeDrawer('ruleForm', 'drawer_user')"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
    <!-- 更新配额 -->
    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      title="更新配额"
      size="20%"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="form"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="域">
            <div class="form_bor">
              {{ form.domain_name }}
            </div>
          </el-form-item>
          <el-form-item label="用户上限" prop="user_sub_limit">
            <el-input v-model="form.user_sub_limit" />
          </el-form-item>
          <el-form-item label="用户组上限" prop="group_limit">
            <el-input v-model="form.group_limit" />
          </el-form-item>
          <el-form-item label="角色上限" prop="role_limit">
            <el-input v-model="form.role_limit" />
          </el-form-item>
          <el-form-item label="权限上限" prop="permission_limit">
            <el-input v-model="form.permission_limit" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            :loading="loading_btn"
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submitForm('form')"
          >
            <span class="next-btn-helper">确定</span>
          </el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="closeDrawer('form', 'drawer')"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  quota_list,
  get_email,
  user,
  edit_quota,
  edit_quota_user,
  get_apps
} from '@/api/view'

import config from '@/config'
import { get_refresh_token, getToken } from '@/utils/auth'

import { handleClipboard } from '../utils/clipboard'

import { getQueryString } from '@/utils'

export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.confirm_password !== '') {
          this.$refs.ruleForm.validateField('confirm_password')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userInfo: {},
      isTooltip: false,
      show_list: false,
      loading_btn: false,
      loading: false,
      drawer: false,
      drawer_user: false,
      form: {
        field: 'ainnovation'
      },
      ruleForm: {
        checked: false,
        age: '',
        pass: '',
        checkPass: '',
        is_update_password: false
      },
      rules: {
        user_sub_limit: {
          required: true,
          message: '请输入用户上限',
          trigger: 'blur'
        },
        group_limit: {
          required: true,
          message: '请输入用户组上限',
          trigger: 'blur'
        },
        role_limit: {
          required: true,
          message: '请输入角色上限',
          trigger: 'blur'
        },
        permission_limit: {
          required: true,
          message: '请输入权限上限',
          trigger: 'blur'
        },
        new_password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirm_password: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        old_password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ]
      },
      tableData: [],
      appList: [],
      not_can_use_client_list: []
    }
  },
  computed: {
    storeInfo () {
      return this.$store.state.userInfo
    },
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    },
    location () {
      const domain = this.userInfo.domain_name || 'admin'
      const { href } = this.$router.resolve({
        path: (domain || '') + '/login'
      })

      return location.origin + href
    },
    oldTooltip () {
      const isMain = this.$store.state.userInfo.user_type === 1

      return isMain ? '若忘记旧密码，可联系管理员重置密码，或在登录页使用“忘记密码”功能进行重置。' : '若忘记旧密码，请联系管理员重置密码。'
    }
  },
  async  created () {
    this.get_info()
    this.get_list()
    this.get_apps_view()
  },
  mounted () {
    const errType = getQueryString('client_login_error')

    if (errType) {
      alert(`${errType}账户被锁定，请联系IT运维部。`)
      const query = Object.assign({}, this.$route.query, { client_login_error: null })
      this.$router.push({ query })
    }
  },
  methods: {
    // 复制登录地址
    copy (val) {
      this.isTooltip = true
      setTimeout(() => {
        this.isTooltip = false
      }, 200)
      handleClipboard(val)
    },
    get_apps_view () {
      const params = {
        domain_id: this.$store.state.userInfo.domain_id,
        user_id: this.$store.state.userInfo.user_id,
        user_type: this.$store.state.userInfo.user_type
      }
      get_apps(params).then((res) => {
        this.appList = res.can_use_client_list
        this.not_can_use_client_list = res.not_can_use_client_list
      })
    },

    open_drawer () {
      this.drawer_user = true

      const params = {
        user_id: this.$store.state.userInfo.user_id
      }
      user(params, this.$store.state.userInfo.is_super).then((res) => {
        res.data.is_update_password = false
        this.ruleForm = JSON.parse(JSON.stringify(res.data))
      })
    },
    submitUser (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const postData = {
            ...this.ruleForm,
            user_id: this.ruleForm.id
          }
          delete postData.parent_domain_name

          edit_quota_user(postData).then((res) => {
            this.get_info()

            this.drawer_user = false
            if (res.code === 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
              if (postData.new_password && postData.confirm_password && postData.is_update_password) {
                // 修改密码之后重新登录
                this.$store.dispatch('LogOut', true).then(() => {
                  location.href = '/view'
                })
              } else {
                this.get_list()
              }
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    get_info () {
      const params = { user_id: this.$store.state.userInfo.user_id }
      user(params, this.$store.state.userInfo.user_type).then((res) => {
        this.userInfo = JSON.parse(JSON.stringify(res.data))
        res.data.name && this.$store.dispatch('setHeaderUserName', res.data.name)
      })
    },
    get_list () {
      this.loading = true
      quota_list()
        .then((res) => {
          this.loading = false
          this.tableData = res.data
          this.show_list = res.msg.indexOf('无权限操作') > -1
        })
        .catch(() => {
          this.loading = false
        })
    },

    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading_btn = true
          const data = this.form
          edit_quota(data)
            .then((res) => {
              this.drawer = false
              this.loading_btn = false
              this.get_list()
            })
            .catch(() => {
              this.drawer = false
              this.loading_btn = false
              this.get_list()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    close () {
      this.$refs.drawer_user.closeDrawer()
      this.$refs.ruleForm.resetFields()
    },
    edit (row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.drawer = true
    },
    applyChangeQuota () {
      get_email()
        .then((res) => {
          this.$alert(`请联系配额负责人-邮箱：${res.data}`, '申请变更配额', {
            confirmButtonText: '确定',
            confirmButtonClass: 'next-btn-primary',
            callback: (action) => {
              action()
            }
          })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: 'error',
            type: 'error'
          })
        })
    },
    closeDrawer (formName, drawer) {
      this.$refs[formName].resetFields()
      this[drawer] = false
    },
    link (url) {
      const url_obj = {
        [config.tokenKey]: getToken(),
        [config.refreshKey]: get_refresh_token()
      }

      const link = url + this.objectToQuery(url_obj)

      window.open(link, '_blank')
    },
    objectToQuery () {
      const obj = arguments[0]
      const prefix = arguments[1]
      if (typeof obj !== 'object') return ''
      const attrs = Object.keys(obj)
      return attrs.reduce((query, attr, index) => {
        // 判断是否是第一层第一个循环
        if (index === 0 && !prefix) query += '?'
        if (typeof obj[attr] === 'object') {
          const subPrefix = prefix ? `${prefix}[${attr}]` : attr
          query += this.objectToQuery(obj[attr], subPrefix)
        } else {
          if (prefix) {
            query += `${prefix}[${attr}]=${obj[attr]}`
          } else {
            query += `${attr}=${obj[attr]}`
          }
        }
        // 判断是否是第一层最后一个循环
        if (index !== attrs.length - 1) query += '&'
        return query
      }, '')
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 16px;
  }
  .el-descriptions {
    padding: 0 10px;
  }
  .next-loading {
    width: 100%;
  }
  .fLwpMi {
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .fLwpMi > h3 {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
  }
  h3,
  h4 {
    margin-bottom: 7px !important;
    font-size: 14px;
  }
  .fLwpMi div:first-of-type {
    display: inline-block;
    margin-left: 40px;
  }
  .cGFAXW {
    margin-bottom: 20px;
  }
  .fLwpMi {
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .eEgMuO {
    display: flex;
    margin-bottom: 8px;
    width: 50%;
    min-width: 300px;
  }
  .TJhJs {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .eRLVLq {
    margin-right: 16px;
    width: 164px;
  }
}
</style>
